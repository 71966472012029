import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logomain from "../assets/images/logo.png";
import { login } from "../Services/login";

function Login() {
  const navigate = useNavigate();
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [err, setErr] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userName || !password) {
      console.log("fldjflj")
      setErr("Username and password required");
      return;
    }
    try {
      const data = await login(userName, password);
      if (data.token) {
        localStorage.setItem("token", data.token);
        navigate("/adduser");
      }
    } catch (err) {
      console.log(err);
      if (err.response.data.message) {
        setErr(err.response.data.message);
      }
    }
  };

  useEffect(() => {
    console.log("err:", err);
  }, [err]);

  return (
    <div className="back-sec">
      <div className="new">
        <form className="form-sec" onSubmit={handleSubmit}>
          <p style={{ color: "red" }}>{err}</p>
          <div className="logo">
            <img src={logomain} className="App-logo" alt="logo" />
          </div>
          <p>Login Here <i class="fas fa-sign-in"></i></p>
          <div className="input">
            <label>Username</label>
            <input
              type="text"
              className="inputField"
              value={userName}
              placeholder="Username"
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="input">
          <label>Password</label>
            <input
              type="password"
              className="inputField"
              value={password}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit" className="sub">
            Submit
          </button>
          <p className="company-link"><a href="http://www.entab.in" target="_blank" class="bottom-text-en3m">Entab Infotech Pvt. Ltd</a></p>
        </form>
        
      </div>
    </div>
  );
}
export default Login;
