import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Addusers from './pages/adduser';
import NewUser from './pages/Newuser';
import SchoolModule from './pages/Schoolmodule';
import Login from './pages/login';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes> 
          <Route path="/" element={<Login />} />
          <Route path="/adduser" element={<Addusers />} />
          <Route path="/newuser" element={<NewUser />} />
          <Route path="/schoolmodule" element={<SchoolModule />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
