import axios from "axios"
import { baseUrl } from "../config"

export const login = async(username, password) => {
    const { data } = await axios.post(baseUrl + "/api/superLoginRoute", { name: username, password })
    console.log(data)
    return data;
}




