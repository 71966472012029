import React, { useEffect, useState } from 'react';
import logo from '../assets/images/logo.png';
import arrange from '../assets/images/arrange.png';
import Button from '../components/Button';
import Users from '../components/Users';
import Support from '../components/Support';
import '../assets/styles/Newuser.css';
import '../assets/styles/users.css';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { baseUrl } from '../config';

const Addusers = () => {
  const [userData, setUserData] = useState(() => {
    const cachedData = localStorage.getItem('userData');
    return cachedData ? JSON.parse(cachedData) : [];
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [dataChange, setDataChange] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/schoolAdmin/all`);
        const data = response.data;
        setUserData(data);
        localStorage.setItem('userData', JSON.stringify(data));
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData();
  }, [dataChange]);

  const deleteUser = async (id) => {
    try {
      const response = await axios.delete(`${baseUrl}/api/schoolAdmin/${id}`);
      console.log('Delete response:', response.data); // Debugging line
      return response.data;
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        const response = await axios.delete(`${baseUrl}/api/schoolAdmin?id=${id}`);
        console.log('Delete response:', response.data); // Debugging line
        if (response.data.message === "Data Deleted Successfully") {
          const newUserList = userData.filter(user => user._id !== id);
          setUserData(newUserList);
          localStorage.setItem('userData', JSON.stringify(newUserList));
          setDataChange(!dataChange); // Optional: Force re-fetching data if needed
        }
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
  };
  

  const handleSort = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
    sortUserData(newSortOrder);
  };

  const sortUserData = (order) => {
    const sortedData = [...userData].sort((a, b) => {
      if (!a.schoolName || !b.schoolName) {
        return 0;
      }
      return order === 'asc' ? a.schoolName.localeCompare(b.schoolName) : b.schoolName.localeCompare(a.schoolName);
    });
    setUserData(sortedData);
    localStorage.setItem('userData', JSON.stringify(sortedData));
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const navigate = useNavigate();
  const routeChange = () => {
    navigate('/Newuser');
  };

  const filteredData = userData.filter(user => {
    return (user.schoolName && user.schoolName.toLowerCase().includes(searchTerm)) ||
           (user.schoolcode && user.schoolcode.toLowerCase().includes(searchTerm));
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="userPage">
      <div className="sub-user">
        <Support />
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
        <div className="user-list">
          {/* <h3>Effortless Management of Schools and Credentials.</h3> */}
          <div className="search-bar">
            <input type="text" placeholder="Search by school name" value={searchTerm} onChange={handleSearch} />
            <Button onClick={handleSort}><img src={arrange} alt="Sort" /></Button>
            <Button onClick={routeChange}>+ Add User</Button>
          </div>
          <div className="users-blk">
            {currentItems.map((user, index) => (
              <div className="user-block" key={user._id}>
                <div className="delete" onClick={() => handleDelete(user._id)}>
                  <img src='/cross.png' alt="Delete" />
                </div>
                <Link to={`/SchoolModule?id=${user._id}`} key={index}>
                  <Users
                    id={user.id}
                    name={user.schoolName}
                    imageUrl={`${baseUrl}/api/image?url=${user.logo}`}
                    schoolCode={user.schoolcode}
                    onDelete={handleDelete}
                  />
                </Link>
              </div>
            ))}
          </div>
          <ul className="pagination">
            {Array.from({ length: Math.ceil(filteredData.length / itemsPerPage) }, (_, i) => (
              <li key={i} className={currentPage === i + 1 ? 'active' : ''}>
                <button onClick={() => paginate(i + 1)}>{i + 1}</button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Addusers;
