// Button.js
import React from 'react';


const Button = ({ onClick, children }) => {
//   const history = useHistory();

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <button className="dark-btn" onClick={handleClick}>{children}</button>
  );
}

export default Button;
