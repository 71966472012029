import React, { useState, useEffect } from "react";
import logomain from "../assets/images/logo.png";
import { Pencil } from "bootstrap-icons-react";
import { Link } from "react-router-dom";
import Support from "../components/Support";
import axios from "axios";

import { baseUrl } from "../config";

const NewUser = ({ label, checked, onChange }) => {
  const [formData, setFormData] = useState({
    logo: "",
    minutesAttachment: "",
    schoolcode: "",
    schoolName: "",
    userId: "",
    password: "",
    contactPersons: [],
    devTeamMember: [],
    minutes: [],
    workFlowDate: "",
    workOrder: "",
    school: "",
    highlight: "",
    event: "",
    circular: "",
    notification: "",
    birthday: "",
    tc: "",
    slider: "",
    videoGallery: "",
    topper: "",
    message: "",
    onlineRegistration: "",
    testimonial: "",
    gallery: "",
    news: "",
    faculty: "",
    activity: "",
    achivement: "",
    kidsCorner: "",
    newsletter: "",
    syllabus: "",
    datesheet: "",
    magazine: "",
    calender: "",
    dipscoop: "",
    loquacity: "",
    form: "",
    useFullLinks: "",
    chairman: "",
    president: "",
    vicePrincipal: "",
    principal: "",
    bishop: "",
    manager: "",
    director: "",
    brother: "",
    newsUrl: "",
    newsImage: "",
    tender: "",
    career: "",
    journey: "",
    playlist: "",
    mediaGallery: "",
    reading: "",
    thought: "",
    JPICGallery: "",
    weeklyMenu: "",
    AQAR: "",
    reflection: "",
    bookShelf: "",
    healthMinistry: "",
    socialMinistry: "",
    pastralMinistry: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation checks
    if (!formData.schoolcode) {
      alert("Please enter school code");
      return;
    }

    if (!formData.schoolName) {
      alert("Please enter school name");
      return;
    }

    if (!formData.userId) {
      alert("Please enter user ID");
      return;
    }

    if (!formData.password) {
      alert("Please enter password");
      return;
    }

    // Additional validation checks for contactPersons array

    try {
      const newFormData = new FormData();

      // Construct FormData for API request
      for (const key in formData) {
        if (Array.isArray(formData[key])) {
          if (key === "logo" || key === "workOrder") {
            formData[key].forEach((file) => newFormData.append(key, file));
          } else if (key === "minutes") {
            formData[key].forEach((item, index) => {
              for (const propKey in item) {
                newFormData.append(
                  `${key}[${index}][${propKey}]`,
                  item[propKey]
                );
              }
              item.minutesAttachment.forEach((file, fileIndex) => {
                newFormData.append(`minutesAttachment`, file);
              });
            });
          } else if (key === "contactPersons" || key === "devTeamMember") {
            formData[key].forEach((item, index) => {
              for (const propKey in item) {
                newFormData.append(
                  `${key}[${index}][${propKey}]`,
                  item[propKey]
                );
              }
            });
          } else {
            console.warn(`Unhandled array type for key: ${key}`);
          }
        } else {
          newFormData.append(key, formData[key]);
        }
      }

      console.log("newFormData", newFormData);

      // Make API call
      const response = await axios.post(
        `${baseUrl}/api/schoolAdmin`,
        newFormData
      );
      console.log("Form submitted successfully", response.data);

      // Reset form fields after successful submission
      setFormData({
        logo: "",
        minutesAttachment: "",
        schoolcode: "",
        schoolName: "",
        userId: "",
        password: "",
        contactPersons: [],
        devTeamMember: [],
        minutes: [],
        workFlowDate: "",
        workOrder: "",
        school: "",
        highlight: "",
        event: "",
        circular: "",
        notification: "",
        birthday: "",
        tc: "",
        slider: "",
        videoGallery: "",
        topper: "",
        message: "",
        onlineRegistration: "",
        testimonial: "",
        gallery: "",
        news: "",
        faculty: "",
        activity: "",
        achivement: "",
        kidsCorner: "",
        newsletter: "",
        syllabus: "",
        datesheet: "",
        magazine: "",
        calender: "",
        dipscoop: "",
        loquacity: "",
        form: "",
        useFullLinks: "",
        chairman: "",
        president: "",
        vicePrincipal: "",
        principal: "",
        bishop: "",
        manager: "",
        director: "",
        brother: "",
        newsUrl: "",
        newsImage: "",
        career: "",
        tender: "",
        journey: "",
        playlist: "",
        mediaGallery: "",
        reading: "",
        thought: "",
        JPICGallery: "",
        weeklyMenu: "",
        AQAR: "",
        reflection: "",
        bookShelf: "",
        healthMinistry: "",
        socialMinistry: "",
        pastralMinistry: "",
      });

      // Optional: Display a success message or perform any additional actions
      window.location.reload();
    } catch (error) {
      console.error("Error submitting form:", error);
      // Optional: Handle error scenarios (e.g., display error message)
    }
  };

  const [selectedImage, setSelectedImage] = useState();
  const [dateCurrent, setDateCurrent] = useState("");
  const [team1, setTeam1] = useState(["Item 1"]);
  const [persons2, setPersons2] = useState(["Item 1"]);
  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const currentDate = `${year}-${month}-${day}`;

    setDateCurrent(currentDate);
  }, []);

  const imageChange = (e) => {
    console.log(e.target.files);
    if (e.target.files && e.target.files.length > 0) {
      const files = [...e.target.files];
      setFormData((prevFormData) => ({
        ...prevFormData,
        [e.target.name]: [files[0]],
      }));
      setSelectedImage(files[0]);
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage();
  };

  const [contactPersonAdd, setContactPersonAdd] = useState([
    {
      personName: "",
      designation: "",
      phone: "",
      email: "",
    },
  ]);

  const handleChangePerson = (e, index) => {
    const { name, value } = e.target;

    setContactPersonAdd((prevState) => {
      const updatedPerson = [...prevState];
      updatedPerson[index][name] = value;
      return updatedPerson;
    });
  };

  useEffect(() => {
    if (contactPersonAdd) {
      setFormData((prevState) => ({
        ...prevState,
        contactPersons: contactPersonAdd,
      }));
    }
  }, [contactPersonAdd]);

  const addContactPerson = () => {
    setContactPersonAdd((prevState) => [
      ...prevState,
      {
        personName: "",
        designation: "",
        phone: "",
        email: "",
      },
    ]);
  };

  const removePerson = (index) => {
    setContactPersonAdd((prevState) => prevState.filter((_, i) => i !== index));
  };

  // development

  const [devPersonAdd, setdevPersonAdd] = useState([
    {
      devName: "",
      devDesignation: "",
      devId: "",
      devEmail: "",
    },
  ]);
  useEffect(() => {
    if (devPersonAdd) {
      setFormData((prevState) => ({
        ...prevState,
        devTeamMember: devPersonAdd,
      }));
    }
  }, [devPersonAdd]);

  const handleChangeDev = (e, index) => {
    const { name, value } = e.target;
    setdevPersonAdd((prevState) => {
      const updatedPerson = [...prevState];
      if (updatedPerson[index]) {
        updatedPerson[index][name] = value;
      }
      return updatedPerson;
    });
  };

  useEffect(() => {
    if (contactPersonAdd) {
      setFormData((prevState) => ({
        ...prevState,
        contactPersons: contactPersonAdd,
      }));
    }
  }, [contactPersonAdd]);

  const adddevPerson = () => {
    setdevPersonAdd((prevState) => [
      ...prevState,
      {
        devName: "",
        devDesignation: "",
        devId: "",
        devEmail: "",
      },
    ]);
  };

  const removedev = (index) => {
    setdevPersonAdd((prevState) => prevState.filter((_, i) => i !== index));
  };

  // Minutes

  const [MinutesPersonAdd, setminutesAdd] = useState([
    {
      schoolContactPerson: "",
      schoolDate: "",
      minutesAttachment: [],
    },
  ]);

  useEffect(() => {
    if (MinutesPersonAdd) {
      setFormData((prevState) => ({
        ...prevState,
        minutes: MinutesPersonAdd,
      }));
    }
  }, [MinutesPersonAdd]);
  // const handleChangeMinutes = (e, index) => {
  //   const { name, files, value } = e.target;
  //   if(name === "minutesAttachment") {
  //     setminutesAdd((prev) => ([

  //     ]))
  //   }
  //   setminutesAdd((prevState) => {
  //     const updatedPerson = [...prevState];
  //     if (updatedPerson[index]) {
  //       // Check if the index exists
  //       updatedPerson[index][name] = value;
  //     }
  //     return updatedPerson;
  //   });
  // };

  const handleChangeMinutes = (e, index) => {
    const { name, value, files } = e.target;
    const newMinutesPersonAdd = [...MinutesPersonAdd];

    if (name === "minutesAttachment") {
      newMinutesPersonAdd[index][name] = [...files];
    } else {
      newMinutesPersonAdd[index][name] = value;
    }

    setminutesAdd(newMinutesPersonAdd);
  };

  useEffect(() => {
    if (MinutesPersonAdd) {
      console.log(MinutesPersonAdd);
    }
  }, [MinutesPersonAdd]);

  useEffect(() => {
    if (contactPersonAdd) {
      setFormData((prevState) => ({
        ...prevState,
        contactPersons: contactPersonAdd,
      }));
    }
  }, [contactPersonAdd]);

  const addMinutesPerson = () => {
    setminutesAdd([
      ...MinutesPersonAdd,
      { schoolContactPerson: "", schoolDate: "", minutesAttachment: [] },
    ]);
  };

  const removeMinutes = (index) => {
    setminutesAdd((prevState) => prevState.filter((_, i) => i !== index));
  };

  return (
    <>
      <div className="userPage active">
        <div className="sub-user">
          <Support />
          <div className="logo">
            <img src={logomain} alt="Logo" />
          </div>
          <div className="backArrow">
            <Link to="/adduser">
              &#8592; <span>Back</span>
            </Link>
          </div>
          <div className="form">
            <form onSubmit={handleSubmit}>
              <div className="d-flex wid-100">
                <div className="imageUpload">
                  <div className="uploadImageBlock">
                    <div className="icon">
                      <Pencil />
                    </div>
                    <input
                      accept="image/*"
                      name="logo"
                      type="file"
                      onChange={imageChange}
                    />

                    {selectedImage && (
                      <div>
                        <img
                          src={URL.createObjectURL(selectedImage)}
                          value={formData.logo}
                          name="logo"
                          alt="Thumb"
                        />
                        <button onClick={removeSelectedImage}>
                          Remove This Image
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="input-feild wid-100">
                  <div>
                    <label>School Code</label>
                    <input
                      type="text"
                      name="schoolcode"
                      onChange={handleChange}
                      value={formData.schoolcode}
                    />
                  </div>
                  <div>
                    <label>School Name</label>
                    <input
                      type="text"
                      name="schoolName"
                      onChange={handleChange}
                      value={formData.schoolName}
                    />
                  </div>
                  <div>
                    <label>User Id</label>
                    <input
                      type="text"
                      name="userId"
                      onChange={handleChange}
                      value={formData.userId}
                    />
                  </div>
                  <div>
                    <label>Password</label>
                    <input
                      type="text"
                      name="password"
                      onChange={handleChange}
                      value={formData.password}
                    />
                  </div>
                </div>
              </div>
              <div>
                <h3 className="d-flex justify-space-between">
                  School Contact Persons Details
                  <div className="addFeild" onClick={addContactPerson}>
                    <div className="dark-btn">+</div>
                  </div>
                </h3>
                {contactPersonAdd?.length &&
                  contactPersonAdd.map((member, index) => (
                    <div className="input-feild wid-100 Addcontact">
                      <div className="wid-4">
                        <label>Person Name</label>
                        <input
                          type="text"
                          name="personName"
                          onChange={(e) => handleChangePerson(e, index)}
                          value={member.personName}
                        />
                      </div>
                      <div className="wid-4">
                        <label>Designation</label>
                        <input
                          type="text"
                          name="designation"
                          onChange={(e) => handleChangePerson(e, index)}
                          value={member.designation}
                        />
                      </div>
                      <div className="wid-4">
                        <label>Phone Number</label>
                        <input
                          type="tel"
                          name="phone"
                          onChange={(e) => handleChangePerson(e, index)}
                          value={member.phone}
                        />
                      </div>
                      <div className="wid-4">
                        <label>Email ID</label>
                        <input
                          type="email"
                          name="email"
                          onChange={(e) => handleChangePerson(e, index)}
                          value={member.email}
                        />
                      </div>
                      <div
                        className="removeBtn"
                        onClick={() => removePerson(index)}
                      >
                        &#10006;
                      </div>
                    </div>
                  ))}
              </div>
              <div>
                <h3 className="d-flex justify-space-between">
                  Development Team Member
                  <div className="addFeild" onClick={adddevPerson}>
                    <div className="dark-btn">+</div>
                  </div>
                </h3>
                {devPersonAdd?.length &&
                  devPersonAdd.map((developer, index) => (
                    <div className="input-feild wid-100">
                      <div className="wid-4">
                        <label>Person Name</label>
                        <input
                          type="text"
                          name="devName"
                          onChange={(e) => handleChangeDev(e, index)}
                          value={developer.devName}
                        />
                      </div>
                      <div className="wid-4">
                        <label>Designation</label>
                        <input
                          type="text"
                          name="devDesignation"
                          onChange={(e) => handleChangeDev(e, index)}
                          value={developer.devDesignation}
                        />
                      </div>
                      <div className="wid-4">
                        <label>Employee Id</label>
                        <input
                          type="text"
                          name="devId"
                          onChange={(e) => handleChangeDev(e, index)}
                          value={developer.devId}
                        />
                      </div>
                      <div className="wid-4">
                        <label>Email ID</label>
                        <input
                          type="email"
                          name="devEmail"
                          onChange={(e) => handleChangeDev(e, index)}
                          value={developer.devEmail}
                        />
                      </div>
                      <div
                        className="removeBtn"
                        onClick={() => removedev(index)}
                      >
                        &#10006;
                      </div>
                    </div>
                  ))}
              </div>
              <div>
                <h3>Work Flow Details</h3>
                <div className="input-feild wid-100">
                  <div>
                    <label>Start Date</label>
                    <input
                      type="date"
                      id="date"
                      name="workFlowDate"
                      value={formData.workFlowDate}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <label>Work Order </label>
                    {team1.map((member, index) => (
                      <div key={index} className="input-feild wid-100">
                        <input
                          type="file"
                          name="workOrder"
                          onChange={imageChange}
                          // value={formData.workOrder}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <h3 className="d-flex justify-space-between">
                  Minutes
                  <div className="addFeild" onClick={addMinutesPerson}>
                    <div className="dark-btn">+</div>
                  </div>
                </h3>

                {MinutesPersonAdd?.length &&
                  MinutesPersonAdd.map((minutes, index) => (
                    <div className="input-feild wid-100">
                      <div className="wid-3">
                        <label>Person Name</label>

                        <div key={index} className="input-feild wid-100">
                          <input
                            type="text"
                            name="schoolContactPerson"
                            onChange={(e) => handleChangeMinutes(e, index)}
                            value={minutes.schoolContactPerson}
                          />
                        </div>
                      </div>
                      <div className="wid-3">
                        <label>Date</label>
                        <input
                          type="date"
                          name="schoolDate"
                          onChange={(e) => handleChangeMinutes(e, index)}
                          value={minutes.schoolDate}
                        />
                      </div>
                      <div className="wid-3">
                        <label>Attach Document </label>

                        <div className="input-feild wid-100">
                          <input
                            type="file"
                            name="minutesAttachment"
                            onChange={(e) => handleChangeMinutes(e, index)}
                          />
                        </div>
                      </div>
                      <div
                        className="removeBtn"
                        onClick={() => removeMinutes(index)}
                      >
                        &#10006;
                      </div>
                    </div>
                  ))}
              </div>
              {/* <input type="submit" value="Submit" /> */}
              <div className="modules">
                <h2>Select Modules</h2>
                <ul>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="highlight"
                        onChange={handleChange}
                        value={formData.school}
                      />
                      <span className="checkmark"></span>
                    </label>
                    School
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="highlight"
                        onChange={handleChange}
                        value={formData.highlight}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Highlight
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="event"
                        onChange={handleChange}
                        value={formData.event}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Event
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="circular"
                        onChange={handleChange}
                        value={formData.circular}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Circular
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="notification"
                        onChange={handleChange}
                        value={formData.notification}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Notification
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="birthday"
                        onChange={handleChange}
                        value={formData.birthday}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Birthday
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="tc"
                        onChange={handleChange}
                        value={formData.tc}
                      />
                      <span className="checkmark"></span>
                    </label>
                    TC
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="slider"
                        onChange={handleChange}
                        value={formData.slider}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Slider
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="videoGallery"
                        onChange={handleChange}
                        value={formData.videoGallery}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Video Gallery
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="topper"
                        onChange={handleChange}
                        value={formData.topper}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Topper
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="message"
                        onChange={handleChange}
                        value={formData.message}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Message
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="onlineRegistration"
                        onChange={handleChange}
                        value={formData.onlineRegistration}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Online Registration
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="testimonial"
                        onChange={handleChange}
                        value={formData.testimonial}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Testimonial
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="gallery"
                        onChange={handleChange}
                        value={formData.gallery}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Gallery
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="news"
                        onChange={handleChange}
                        value={formData.news}
                      />
                      <span className="checkmark"></span>
                    </label>
                    News
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="faculty"
                        onChange={handleChange}
                        value={formData.faculty}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Faculty
                  </li>

                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="activity"
                        onChange={handleChange}
                        value={formData.activity}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Activity
                  </li>

                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="achivement"
                        onChange={handleChange}
                        value={formData.achivement}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Achivements
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="kidsCorner"
                        onChange={handleChange}
                        value={formData.kidsCorner}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Kids Corner
                  </li>

                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="newsletter"
                        onChange={handleChange}
                        value={formData.newsletter}
                      />
                      <span className="checkmark"></span>
                    </label>
                    NewsLetter
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="syllabus"
                        onChange={handleChange}
                        value={formData.syllabus}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Syllabus
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="datesheet"
                        onChange={handleChange}
                        value={formData.datesheet}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Datesheet
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="magazine"
                        onChange={handleChange}
                        value={formData.magazine}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Magazine
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="calender"
                        onChange={handleChange}
                        value={formData.calender}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Academic Calender
                  </li>

                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="form"
                        onChange={handleChange}
                        value={formData.form}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Enquiry Form
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="useFullLinks"
                        onChange={handleChange}
                        value={formData.useFullLinks}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Usefull links
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="chairman"
                        onChange={handleChange}
                        value={formData.chairman}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Chairman's Message
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="vicePrincipal"
                        onChange={handleChange}
                        value={formData.vicePrincipal}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Vice Principal's Message
                  </li>

                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="manager"
                        onChange={handleChange}
                        value={formData.manager}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Manager's Message
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="director"
                        onChange={handleChange}
                        value={formData.director}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Director's Message
                  </li>

                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="principal"
                        onChange={handleChange}
                        value={formData.principal}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Principal's Message
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="president"
                        onChange={handleChange}
                        value={formData.president}
                      />
                      <span className="checkmark"></span>
                    </label>
                    President's Message
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="mediaGallery"
                        onChange={handleChange}
                        value={formData.mediaGallery}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Media Gallery
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="newsUrl"
                        onChange={handleChange}
                        value={formData.newsUrl}
                      />
                      <span className="checkmark"></span>
                    </label>
                    News Url
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="newsImage"
                        onChange={handleChange}
                        value={formData.newsImage}
                      />
                      <span className="checkmark"></span>
                    </label>
                    News Image
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="tender"
                        onChange={handleChange}
                        value={formData.tender}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Tender
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="career"
                        onChange={handleChange}
                        value={formData.career}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Career
                  </li>
                </ul>
                <h2>Special Modules</h2>
                <ul>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="dipscoop"
                        onChange={handleChange}
                        value={formData.dipscoop}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Dipscoop
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="loquacity"
                        onChange={handleChange}
                        value={formData.loquacity}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Loquacity
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="bishop"
                        onChange={handleChange}
                        value={formData.bishop}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Bishop's Message
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="brother"
                        onChange={handleChange}
                        value={formData.brother}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Brother's Message
                  </li>

                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="journey"
                        onChange={handleChange}
                        value={formData.journey}
                      />
                      <span className="checkmark"></span>
                    </label>
                    JMV-Apostolic
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="playlist"
                        onChange={handleChange}
                        value={formData.playlist}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Playlist
                  </li>

                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="reading"
                        onChange={handleChange}
                        value={formData.reading}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Reading
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="thought"
                        onChange={handleChange}
                        value={formData.thought}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Thought Of The Day
                  </li>                  
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="JPICGallery"
                        onChange={handleChange}
                        value={formData.JPICGallery}
                      />
                      <span className="checkmark"></span>
                    </label>
                    JPIC Gallery
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="weeklyMenu"
                        onChange={handleChange}
                        value={formData.weeklyMenu}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Weekly Menu
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="AQAR"
                        onChange={handleChange}
                        value={formData.AQAR}
                      />
                      <span className="checkmark"></span>
                    </label>
                    AQAR
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="reflection"
                        onChange={handleChange}
                        value={formData.reflection}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Reflection
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="bookShelf"
                        onChange={handleChange}
                        value={formData.bookShelf}
                      />
                      <span className="checkmark"></span>
                    </label>
                    BookShelf
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="healthMinistry"
                        onChange={handleChange}
                        value={formData.healthMinistry}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Health Ministry
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="socialMinistry"
                        onChange={handleChange}
                        value={formData.socialMinistry}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Social Ministry
                  </li>
                  <li>
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checkbox="false"
                        name="pastralMinistry"
                        onChange={handleChange}
                        value={formData.pastralMinistry}
                      />
                      <span className="checkmark"></span>
                    </label>
                    Pastral Ministry
                  </li>
                </ul>
                <input type="submit" value="Submit" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewUser;
