export const baseUrl="https://webapi.entab.info"
// export const baseUrl="http://localhost:4000";

export const getHeader = () => {

    const token = localStorage.getItem('token');
    const schoolcode = localStorage.getItem('schoolcode');

    return {
        header: {
            'Authorization': `Bearer ${token}`
        },
        schoolcode,
    }
}