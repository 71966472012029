import React from 'react';
// import SchoolModule from './Schoolmodule'


const Users = ({ name, imageUrl, schoolCode }) => {
    return (
        <div className="inner-block">
            
             <div> 
                <img src={imageUrl} alt={name} />
                <div>{name}</div>
                <p>{schoolCode}</p> 
            </div>
        </div>
    );
}

export default Users;   